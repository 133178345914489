@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

